import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import {StaticQuery, graphql, Link} from 'gatsby'
import Img from "gatsby-image"

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 1em;
`
const RoofImage = styled(Img)`
  width: 100%
`
const Copy = styled.div`
  padding: 1.5rem;
`

const ResidentialPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          bar: file(relativePath: { eq: "stuccohome.png" }) {
            childImageSharp {
              fluid(quality: 20, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Residential" />
          
          <ImageContainer>
            <RoofImage fluid={data.bar.childImageSharp.fluid}></RoofImage>
          </ImageContainer>

          <Copy>
From new construction to small repairs, our experienced team of roofing professionals can complete your residential roofing job quickly and efficiently using industry-leading practices and safety measures.            <br/>
            <br/>
To request a free quote, fill out the form to the right or <Link to='/contactus'>contact us</Link> to have one of our staff members call or email you at your convenience.          </Copy>


        </Layout>
      )}
    />
  )
}

export default ResidentialPage
