import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

const HeaderContainer = styled.div`
  margin-bottom: 1.45rem;
`
const UpperOuterContainer = styled.div`
  margin: 0 auto;
`
const UpperInnerContainer = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  display: flex;
  justify-content: space-between;
`

const HeaderWebsiteName = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-flow: column nowrap;
`
const TitleLink = styled(Link)`
  font-size: 2rem;
  color: Black;
  text-decoration: none;
`
const Title = styled.div`
  display: flex;
  font-size: 3rem;
  font-weight: 800;
  color: Black;
  margin: .5rem;
`
const SubTitle = styled.div`
  dipslay: flex;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin: .8rem;
  color: black;
`

const HeaderImageContainer = styled.div`
  width: 9rem;
`
const HeaderImage = styled(Img)`
  width: 100%;
`

const LowerOuterContainer = styled.div`
  margin: 0 auto;
  background: steelblue;
`
const LowerInnerContainer = styled.div`
  margin: 0 auto;
  max-width: 1100px;
  padding: .7rem 1.0875rem;
  display: flex;
  flex-flow: row-nowrap;
  justify-content: flex-start;
`
const MenuItem = styled.div`
  color: white;
  text-decoration: none;
  padding: 0 1rem;
`
const StyledMenuLink = styled(Link)`
  color: white;
  text-decoration: none; 
  font-weight: 700; 
`

const Header = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          image: file(relativePath: { eq: "roof1.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <HeaderContainer>
          <UpperOuterContainer>
            <UpperInnerContainer>
              <HeaderWebsiteName>
                <TitleLink to="/"><Title>The Villages Roofing</Title></TitleLink>
                <SubTitle>Residential - Commercial</SubTitle>
              </HeaderWebsiteName>
              <HeaderImageContainer>
                <HeaderImage fluid={data.image.childImageSharp.fluid}></HeaderImage>
              </HeaderImageContainer>
            </UpperInnerContainer>
          </UpperOuterContainer>
          <LowerOuterContainer>
            <LowerInnerContainer>
              <MenuItem><StyledMenuLink to="/">Home</StyledMenuLink></MenuItem>
              <MenuItem><StyledMenuLink to="/residential">Residential Roofing</StyledMenuLink></MenuItem>
              <MenuItem><StyledMenuLink to="/commercial">Commercial Roofing</StyledMenuLink></MenuItem>
              <MenuItem><StyledMenuLink to="/repair">Roof Repair</StyledMenuLink></MenuItem>
              <MenuItem><StyledMenuLink to="/contactus">Contact Us</StyledMenuLink></MenuItem>
            </LowerInnerContainer>
          </LowerOuterContainer>
        </HeaderContainer>
      )}
    />
  )
}

export default Header
